@import "../template/mixin_templates";

/************************************************/
/****************** ANIMATIONS ******************/
/************************************************/

@keyframes menuLiMove {
    0% {
        transform: translate(0px, 10px);
    }
    20% {
        transform: translate(0px, -7px);
    }
    40% {
        transform: translate(0, 5px);
    }
    60% {
        transform: translate(0, -3px);
    }
    80% {
        transform: translate(0, 0);
    }
}

/* Wobble Vertical */

@keyframes hvr-wobble-vertical {
    16.65% {
        transform: translateY(10px);
    }

    33.3% {
        transform: translateY(-8px);
    }

    49.95% {
        transform: translateY(5px);
    }

    66.6% {
        transform: translateY(-2px);
    }

    83.25% {
        transform: translateY(1px);
    }

    100% {
        transform: translateY(0);
    }
}

/*********************************************/
/****************** GENERAL ******************/
/*********************************************/

* {
    box-sizing: border-box;
}
html {
    font-size: 100%;
}
body {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
}
@include titreBaseSize();
b, strong {
    font-weight: bold;
}
i, em {
    font-style: italic;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
iframe{
    max-width:100%;
}

.wrapper-content{
    overflow:hidden;
}

.footer_p table, .footer_p table img {
    max-width: 100% !important;
    height:auto !important;
}

.wrapper h1, .wrapper h2, .wrapper h3,
.wrapper h4, .wrapper h5, .wrapper h6,
.wrapper .h1, .wrapper .h2, .wrapper .h3,
.wrapper .h4, .wrapper .h5, .wrapper .h6 {
    margin-bottom: .5rem;
    margin-top: .2rem;
    line-height: 1.4;
    font-weight: bold;
}
.wrapper h1,
.wrapper .h1 {
    font-size: 2rem;
}
.wrapper h2,
.wrapper .h2,
.single-article .titre-article h1,
.single-article .titre-article .h1 {
    font-size: 1.8rem;
    padding: 0 60px;
    position: relative;
    margin: 0 auto 10px;
    display: inline-block;
    font-weight: normal;
}
.wrapper h2::before,
.wrapper .h2::before,
.single-article .titre-article h1::before,
.single-article .titre-article .h1::before {
    content: "";
    display: block;
    height: 16px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */

    opacity: 0.5;
}

.wrapper h3, .wrapper .h3 {
    font-size: 1.6rem;
}

.wrapper h4, .wrapper .h4 {
    font-size: 1.4rem;
}

.wrapper h5, .wrapper .h5 {
    font-size: 1.2rem;
}

.wrapper h6, .wrapper .h6 {
    font-size: 1rem;
}

.button, button {
    display: inline-block;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    border-width: 1px;
    border-style: solid;
    font-size: 1rem;
    cursor: pointer;
}

.button .fa {
    margin-right: 5px;
}

#wrapper #fiche-produit .previews .previous,
#wrapper #fiche-produit .previews .next {
    font-size: 20px;
    line-height: 60px;
    z-index: 2;
}

.zoomContainer {
    z-index: 2;
}



.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.col-md-10 .secondaire {
    //display: none;
}

/********************************************/
/****************** HEADER ******************/
/********************************************/

div.header_p {
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

header {
    position: relative;
    z-index: 5;
}

#headercontent {
    position: relative;
    height: 115px;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
}

.logo{
    display: inline-block;
    img{
        max-width:100%;
        max-height:100px;
    }
}

.slogan {
    position: absolute;
    top: 25px;
    left: 400px;
    font-size: 2rem;
    text-align: left;
    margin: 0;
}

#headercontent .facebook {
    text-align: center;
    float: right;
}

#headercontent img {
    max-height: 105px;
}

.menu {
    min-height: 48px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

nav#nav-principal {
    margin: 0 auto;
    position: relative;
    width: $large-screen - 20;
    z-index: 1;
    &::before,&::after{
        content:'';
        display:block;
        clear:both;
    }
}

nav#nav-principal > ul {
    position: relative;
    margin: 0;
}

nav ul {
    padding-left: 0;
}

nav li {
    list-style: none;
}

nav a {
    text-decoration: none;
}

nav#nav-principal > ul > li {
    float: left;
    line-height: 1.5;
    display: block;
    text-decoration: none;
    margin-right: 2px;
    transition: color .4s linear;
    //position: relative;
}

nav#nav-principal > ul > li:hover {
    animation: menuLiMove 1s cubic-bezier(.36,.07,.19,.97);
    z-index: 2;
}

nav#nav-principal > ul > li:last-child {
    margin-right: 0;
}

nav#nav-principal > ul > li > ul {
    overflow: hidden;
    height: 0;
    position: absolute;
}

nav#nav-principal ul li:hover > ul {
    height: auto;
    overflow:visible;
}

nav#nav-principal ul li a {
    display: block;
    padding: 14px 10px;
    text-decoration: none;
    white-space: normal;
}

nav#nav-principal > ul > li{
    > a{
        position:relative;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            top:0;
            left:0;
        }
    }
    &:hover{
        > a::after{
            padding-bottom:10px;
        }
    }
}

nav#nav-principal .slide-toggle {
    clear: both;
    position: relative;
    z-index: 10000 !important;
}
nav#nav-principal ul li:hover{
    position:relative;
}
nav#nav-principal ul li ul {
    position: absolute;
    z-index: 10000 !important;
    min-width:180px;
    width:100%;
    a{

    }
    li{
        position:relative;
    }
    ul{
        left:100%;
        top:0;
        height:0;
        overflow:hidden;
    }
}

nav#nav ul li ul li {
    min-width: 150px;
}

.hvr-wobble-vertical {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
}

.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
    animation-name: hvr-wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

/* Test menu */

nav#nav-principal > ul > li.actif > a {
    position: relative;
}

nav#nav-principal > ul > li.actif > a::before {
    content: "";
    display: block;
    height: 16px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */

    opacity: .5;
}

.slide-toggle ul {
    display: none;
}

/***** FIN HEADER *****/

/*********************************************/
/****************** CONTENU ******************/
/*********************************************/

.wrapper {
    width: $large-screen - 20;
    margin: auto auto 15px;
    padding: 30px 15px 0;
    &.accueil_diaporama_template{
        padding:0;
        min-height: 0;

    }
    &::before,&::after{
        content:'';
        display:block;
        clear:both;
    }
}

/* Diaporama accueil */
.accueil_diaporama_template{
    .slide .slide-text{
        .addcommentaires{
            width:70px;
            height:55px;
            box-sizing:border-box;
            top:10px;
            right:10px;
            margin:0;
        }
        .content-slide-text > p{
            top: 75px;
            left: auto;
            margin: 0;
            right: 10px;
            bottom:auto;
        }
        .content-slide-text > a.slider-see-more{
            top: 10px;
            right: 80px;
            padding: 0 10px;
            height: 55px;
            line-height: 55px;
            margin:0;
        }
    }
    ~ .wrapper{
        padding-top:15px;
    }
    &.accueil_diaporama_template_1{
        margin-bottom:0;
        .slide{
            max-width: 100%;
        }
        .bx-viewport{
            max-width: 100%;
            padding: 0 15px;
        }
        .bx-wrapper{
            margin-bottom:0;
        }
        .center-img-slider{
            display: block;
            margin: 0;
            text-align: center;
            img{
                display: inline-block;
                margin:0;
            }
        }
        .bx-pager{
            margin: 0;
            padding: 10px 0;
        }
        .bx-controls-direction{
            .bx-prev{
                left:30px;
            }
            .bx-next{
                right: 30px;
            }
        }
    }
    &.accueil_diaporama_template_2{
        margin-bottom:0;
        .bx-wrapper .bx-pager{
            position: absolute !important;
            top:auto;
            bottom:15px;
        }
    }
    &.accueil_diaporama_template_3{
        margin-bottom:0;
    }
    &.accueil_diaporama_template_4,
    &.accueil_diaporama_template_6{
        margin-bottom:10px;
    }
    &.accueil_diaporama_template_5{
        margin-bottom:0;
        .bx-wrapper{
            margin-bottom:0;
        }
        .slide .slide-text .addcommentaires{
            padding:20px 10px;
            text-align: center;
        }
    }
}

.wrapper img {
    max-width: 100%;
}

aside.sidebar {
    width: 16.66666667%;
    float: left;
    margin-top: 13px;
}

#nav-static-principal a {
    display: block;
    padding: 10px;
}

#nav-static-principal > ul > li > a {
    font-size: 1rem;
    text-transform: uppercase;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 5px 10px;
    margin-bottom: 5px;
}

#nav-static-principal ul {
    margin-top: 0;
}

#nav-static-principal ul ul li {
    border-bottom: 1px solid #fff;
}

#nav-static-principal ul ul ul li {
    border-bottom: none;
}

#nav-static-principal ul ul li a {
    padding-left: 20px;
}
#nav-static-principal ul ul ul li a {
    padding-left: 30px;
}

.col-md-10 {
    width: 83.33333333%;
    float: left;
    padding-left: 25px;
    padding-right: 10px;
}

#wrapper nav.secondaire li {
    float: left;
    padding: 5px;
}

.formulaires_perso, .form-newsletter.roundedgraymsg {
    border: 1px solid #ccc;
    color: #222;

    div.center, .center {
        margin: 0 !important;
        font-size: 1.2rem;
        font-weight: bold;
    }
    input[type=text], textarea {
        width: 100% !important;
        border-width: 1px;
        border-style: solid;
        outline: 0;
    }
    textarea {
        height: 100px;
    }
    input[type=submit] {
        padding: 5px 20px !important;
        font-size: .95rem !important;
    }
}

.form-newsletter input[type=submit] {
    display: block;
    margin: 5px auto;
}

.roundedgraymsg {
    border: none;
}

/* Bx-slider diapo */
.bx-wrapper {
    padding: 0;
    position: relative;
    margin-bottom:0;
}
.bx-wrapper .bx-viewport{
    left:0;
}
.bx-wrapper img {
    width: 100%;
    display: block;
}

.bx-wrapper .bx-loading {
    min-height: 50px;
    background: url(/images/bx_loader.gif) center center no-repeat;
}

.bx-wrapper .bx-pager {
    font-family: Arial, sans-serif;
    font-size: 0.85em;
    font-weight: bold;
    padding-bottom: 10px;
    margin-top: 10px;
    position: relative !important;
    text-align: center;
    top: 0;
}

.bx-wrapper .bx-pager .bx-pager-item, .bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #000;
}

.bx-wrapper .bx-prev {
    left: 0;
    background: url(/images/controls.png) no-repeat 0 -32px;
    transition-property: all;
    transition-timing-function: ease-in-out;
}

.bx-wrapper .bx-next {
    right: 0;
    background: url(/images/controls.png) no-repeat -43px -32px;
    transition-property: all;
    transition-timing-function: ease-in-out;
    margin-top: 10px;
}

.bx-wrapper:hover .bx-prev {
    background-position: 0 0;
}

.bx-wrapper:hover .bx-next {
    background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 45%;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 100;
}

.bx-wrapper .bx-controls-direction a.disabled {
    display: none;
}

.bx-wrapper .bx-controls-auto {
    text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
    display: block;
    text-indent: -9999px;
    width: 10px;
    height: 11px;
    background: url(/images/controls.png) -86px -11px no-repeat;
    margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover, .bx-wrapper .bx-controls-auto .bx-start.active {
    background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    background: url(/images/controls.png) -86px -44px no-repeat;
    margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover, .bx-wrapper .bx-controls-auto .bx-stop.active {
    background-position: -86px -33px;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
    text-align: left;
    width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
    right: 0;
    width: 35px;
}

.bx-wrapper .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #666 \9
;
    background: rgba(80, 80, 80, 0.75);
    width: 100%;
}

.bx-wrapper .bx-caption span {
    font-family: Arialn sans-serif;
    display: block;
    font-size: .85em;
    padding: 10px;
}

.slide {
    position: relative;
    top: 0;
}

.slide-text {
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 65%;
    width: 100%;
}

.slide > div {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.nav-static-breadcrumb {
    ul {
        list-style: none;
        margin: 0;
        padding: 10px 0;

    }
    li {
        float: left;
        font-weight: bold;
        list-style: none;
        margin: 0;
        padding: 0;
        &:not(:last-child)::after {
            color: #cacaca;
            content: "/";
            margin: 0 0.40rem;
            position: relative;
            top: 1px;
            opacity: 1;
        }
    }
}

ul.bxslider-diaporama {
    li {
        text-align: center;
    }
    .bx-caption {
        bottom: 14px;
    }
    img {
        width: auto;
        display: inline-block;
    }
}

.slide-text p, .slide-text a {
    color: #fff;
    text-align: justify;
    padding: 10px 20px;
    display: inline-block;
    text-decoration: none;
}

/* Boutique */
.produits, .galerie {
    position: relative;
    background-color: #fff;
    border: 1px solid #cecece;
}

.produits img, .galerie img {
    width: 100%;
    height: auto;
}
.produits .zoom, .galerie .zoom {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("/images/imgd525series/zoom.png") no-repeat center center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.produits {
    margin-bottom:15px;
    .productImageWrap {
        /*        border-bottom: 1px solid #ccc;*/
        position: relative;
    }
    .productContentWrap{
        padding:10px;
    }
    .zoom {
        height: 100%;

        transform: scaleX(0);
    }
    .remise {
        position: absolute;
        top: 200px;
        right: 0;
        font-weight: bold;
        font-size: 1.3em;
        padding: 5px;
    }
    .attr{
        padding:0;
    }
    .nomprod {
        font-size: 1rem;
        padding-top: 20px;
        border-top: 1px solid #ccc;
        box-sizing: content-box;
        margin-top: 0;
        text-align: center;
    }
    .prix__block {
        justify-content: center;
    }
    .prix{
        text-align: center;

        .oldprix, .prixprod{
            float: none;
            display: inline-block;
        }
        .oldprix{
            margin:0 0 0 5px;
            text-decoration: line-through;
        }
    }
    .btn-boutique-add {
        padding: 0 10px;
        margin-bottom: 10px;

        select, a {
            width: 100%;
        }
        select {
            margin-bottom: 5px;
        }
        a::before {
            content: "\f07a";
            font-family: FontAwesome, sans-serif;
            margin-right: 7px;
        }
    }
    @at-root #fiche-produit .produit_etiquette, & .produit_etiquette {
        width: 100%;
        padding: 4px 15px;
        left: -10px;
        top: 12px;
        height: 30px;

        h3, .h3 {
            font-size: 1rem;
            padding: 0;
            font-weight: normal;
            margin: 0;
        }
        &::before {
            content: "";
            border-style: solid;
            border-width: 6px 10px 0 0;
            border-color: transparent #000 transparent transparent;
            display: block;
            position: absolute;
            top: -6px;
            left: 0;
        }
        &::after {
            border: none;
        }
    }
    .remise{
        height:30px;
        top: 12px;
        right: 0;
        color: #fff;
        padding: 3px 5px;
        span{
            vertical-align: top;
            line-height: 24px;
        }
    }

    &:hover .zoom {
        opacity: .6;
        transform: scaleX(1);
    }

    .note-produit {
        text-align: center;
    }
}

.template_boutique_all{
    .produits{
        .zoom::before{
            content:'';
        }
    }
    &.template_boutique_1 .produits{
        .attr:empty{
            display: none;
        }
        .block-btn-addbasket{
            align-items: flex-end;
            justify-content: flex-start;
            margin: 0 0 15px;
            .addbasket{
                margin-top: 5px;
                padding: 10px;
                &:not(.next-select){
                    width: auto;
                    max-width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
    &.template_boutique_2{
        .produits{
            .productContentWrap{
                text-align: center;
            }
            .hors-stock, .addbasket, select{
                margin: 10px 0;
                float: none;
                text-align: center;
                width: auto;
                max-width: 95%;
            }
            .addbasket{
                padding:8px 15px;
                font-size: 1.2rem;
            }
            select{
                right: 0;
            }
        }
    }
    &.template_boutique_3{
        p.attr {
            text-align: center;
            select {
                max-width: 150px;
                float: none;
                display: inline-block;
            }
        }
        .produits{
            .nomprod {
                border: none;
            }
            &.is_etiquette {
                .container-etiquette{
                    .produit_etiquette{
                        position:absolute;
                    }
                }
                .nomprod {
                    margin-top: 0;
                }
            }
            .container-etiquette{
                left:-10px;
            }
        }
    }
    &.template_boutique_4{
        .produits{
            &.is_etiquette .container-etiquette{
                top:-25px;
            }
            .addbasket{
                width:90%;
            }
            .nomprod{
                box-sizing: border-box;
            }
        }
    }
    &.template_boutique_5{
        .produits{
            .container-etiquette .produit_etiquette{
                left:-21px;
            }
            .nomprod{
                padding: 5px;
            }
            .remise {
                bottom:auto;
                top:60px;
                right: auto;
            }
        }
    }
}

.template_boutique_accueil {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    .produits{
        padding:10px;
        .zoom .blocPlusDetails{
            display:none;
        }
        .container-etiquette{
            z-index:100;
        }
        .productImageWrap, .remise{
            left:auto;
            right:0;
        }
        .remise {
            top: 22px;
            z-index: 101;
            font-size: 15px;
            line-height: 26px;
        }
        position: relative;
        background-color: #fff;
        text-align: center;

        .desc {
            padding: 60px 10px 20px;
        }
        .mask-btn {
            position: absolute;
            bottom: 60px;
            width: 100%;
            a.button {
                background-color: transparent;
                border-color: #fff;
            }
        }
        .zoom {
            position: absolute;
            top: 0;
            width: 100%;
        }
        .hors-stock{
            font-size: 0;
            &::before{
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
            .inside-hors-stock{
                display: inline-block;
                vertical-align: middle;
                font-size: 15px;
            }
        }

        .produit_etiquette{
            left:-21px;
        }
        &:hover{
            .mask {
                opacity: .8;
                transform: scaleX(1);
            }
        }
    }
    .bx-wrapper {
        margin: 0 auto;
    }
    .bx-viewport {
        height: auto !important;
        padding: 0 0 0 10px;
    }
    .mask {
        opacity: 0;
        position: absolute;
        top: -2px;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;

        transform: scaleX(0);
        a {
            height: 100%;
            width: 100%;
            text-decoration: none;
        }
        .desc {
            text-decoration: none;
        }
    }
    img {
        border-bottom: 1px solid #ccc;
    }

}

.template_fiche_produit{
    #fiche-produit {
        .wrap-images {
            img {
                max-width: none;
            }
            .zoomPad > img {
                max-width: 100%;
            }
            .remise-produit{
                //display: none;
            }
        }
        h1, .h1 {
            font-size: 1.6rem;
            margin: 0;
        }
        .declinaison label{
            margin-bottom:0;
            margin-right:5px;
            &.radio_unique, &.radio_color{
                margin:5px;
            }
        }
        .wrap-description .brand {
            opacity: 0.8;
            font-size: 1.4rem;
            margin: 0;
            background:transparent !important;
            color:inherit !important;
            &::before{
                display: none;
            }
            a {
                text-decoration: none;
            }
        }
        nav.secondaire {
            margin-left: 15px;
            display: none;
        }
        .top_fiche {
            border: 1px solid #949494;
            padding: 10px 25px 10px 10px;

            .addbasket {
                margin-right: 0;
                font-size: .95rem;

                i {
                    margin-right: 5px;
                }
            }
            .wrap-details {
                padding: 20px 0 0;
                margin-bottom: 30px;

                h4, .h4 {
                    text-align: center;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #949494;
                }
            }
        }
        .remise-produit {
            border-radius: 0;
            padding: 14px 10px 5px;
            right: -15px;
            top: -16px;
            width: 70px;
            height: 50px;
            position: absolute !important;
        }
        .remise-produit::after {
            content: "";
            border-style: solid;
            border-width: 0 6px 6px 0;
            border-color: transparent transparent #000 transparent;
            display: block;
            position: absolute;
            top: 0;
            right: -6px;
        }
        .wrap-description .fil-dariane {

            i {
                margin-right: 5px;
            }
            ul {
                overflow: hidden;
                margin-top: 5px;
                margin-bottom: 5px;
                padding-bottom: 5px;
                border-bottom: 1px solid #ccc;
            }
        }
        .wrap-description {
            position: relative;
            h3, .h3 {
                //margin: 20px 0 0 0;
                //color: #222;
            }
            .prix {
                border-top: none;
                margin-top: 5px;

                .oldprix {
                    float: right;
                }
            }
            .btn-detail-prod {
                text-align: right;
            }
            .description {
                margin: 25px 0;
            }
        }
        .wrap-images {
            .grande-image, .grande-image > a {
                height: auto;
            }
            a {
                text-decoration: none;
            }
            button {
                width: 82% !important;
                margin: 10px auto;
                font-size: .8rem;
                display: block;
            }
            .triangle-ficheprod .remise-produit {
                height: auto;
                border-radius: 0;
                top: -22px;
                padding-bottom: 12px;
            }
        }
        .wrap-details > h4,
        .wrap-details > .h4 {
            text-align: center;
            margin-bottom: 20px;
            border-bottom: 1px solid #949494;
        }
        .message-wrap, .container-comment {

        }
        .message-wrap {
            box-shadow: none;
        }
        .container-comment {
            margin-top: 45px;
            padding: 10px 0 25px;

            > div {
                box-shadow: none !important;
                border: none !important;
            }
            #entete {
                margin-bottom: 35px;
                text-align: center;
            }
            .google-recaptcha {
                margin-left: -5px;
            }
        }
        .addbasket {
            display: block;
            width: auto;
            padding: 6px 8px;
            font-size: 21px;
            margin: 0;
            height: auto;
        }
    }

    &.template_fiche_produit_1 #fiche-produit{
        border: 1px solid #949494;
         .wrap-description .prix .oldprix {
            float: none;
        }
        .top_fiche .addbasket {
            min-width: 40%;
        }
        .top_fiche {
            border: none;
        }

        .wrap-details {
            padding-right: 15px;
            padding-left: 15px;
        }
        .wrap-images{
            .remise-produit{
                display: none;
            }
        }
        .triangle-ficheprod-second{
            display: block !important;
        }
    }
    &.template_fiche_produit_2 {
        #fiche-produit{
            .wrap-description .container-etiquette .produit_etiquette {
                position: relative;
                h3, .h3{
                    margin:0;
                }
            }
            .produit_etiquette::before{
                display: none;
            }
            .remise-produit {
                position: absolute;
            }
            .wrap-images .triangle-ficheprod .remise-produit {
                height: auto;
                border-radius: 0;
                top: -24px;
                right:0;
                left: auto;
                padding-bottom: 12px;
                display: block;
            }
            .container-etiquette{
                position:relative;
                .produit_etiquette{
                    left: 0;
                }
            }

            .ajouter-panier{
                .block-addbasket{
                    margin-top: 10px;
                }
            }
        }
    }
}

#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .button {
        flex: 0 1 auto;
        font-size: 1.5em;
        margin: 15px 0;
    }

    > .button + .button {
        margin-left: 15px;
    }
}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


#paniercmd {
    margin-bottom: 35px;
}

.wrapper #connexion-inscription h2,
.wrapper #connexion-inscription .h2 {
    padding: 5px 20px;
    line-height: 1;
    font-size: 1.4rem;
    display: block;
}

/* Fin Boutique */

/* Galerie */
.galerie {
    position: relative;
    overflow: hidden;

    .zoom {
        height: 100%;
        transform: translateX(-100%);
    }
    &:hover .zoom {
        opacity: .8;
        transform: translateX(0%);
    }
}


.template_album{
    .galerie .mask{
        background-color: rgba(255, 255, 255, 1);
    }
    .galerie .commentP  p{
        text-align: center;
    }
    &.template_album_2,
    &.template_album_3,
    &.template_album_4,
    &.template_album_5{
        .galerie {
            border:none !important;
        }
    }
    &.template_album_4,
    &.template_album_5{
        .galerie .content_p .mask{
            display: none;
        }
    }
}

.template_album{
    &.template_album_4 .galerie {
        &.sans-texte .bloc-cache-overflow, .bloc-cache-overflow{
            min-height: 60px;
            display:block;
        }
        &.sans-texte.sans-commentaire .bloc-cache-overflow{
            display: none;
        }
    }
    &.template_album_3 .galerie.sans-texte .bloc-cache-overflow{
        display: none;
    }
}

/* Fin Galerie */

/* Livre d'or */
.buttonLivre {
    display: block;
    width: 312px;
    margin: 10px auto;
}

.message_lo {
    margin: 15px 0;
    border: 1px solid #ccc;
    background-color: #fafafa;
    position: relative;
    .sous-titre {
        font-size: 1rem;
        border-bottom: 1px solid #ccc;
        padding: 2px 110px 2px 20px;
        margin-bottom: 8px;
        text-align: left;
        display: block;
        font-weight: normal;
        &::before {
            content: none;
        }
    }
    .note {
        position: absolute;
        right: 10px;
        top: -6px;
        display: block;
        height: 37px;
        padding: 12px 10px;
        margin: 0;
        &::after {
            content: "";
            border-style: solid;
            border-width: 0 6px 6px 0;
            border-color: transparent transparent #000 transparent;
            display: block;
            position: absolute;
            top: 0;
            right: -6px;
        }
    }
    .message{
        padding: 0 10px;
        text-align: center;
        margin: .5rem 0;
        &::before, &::after{
            font-family: FontAwesome, sans-serif;
            display: block;
        }
        &::before {
            content: "\f10d";
            text-align: left;
        }
        &::after {
            content: "\f10e";
            text-align: right;
        }
    }
    .web {
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 4px 10px 3px;
    }
}
.template_livreor{
    &.template_livreor_2{
        .list-messages .message_lo{
            .contenuMsgLO{
                .note{
                    position: absolute;
                }
                p.message{
                    margin-top:25px;
                }
            }
            .IDLO time {
                float: right;
            }
        }
    }
    &.template_livreor_3{
        .list-messages .message_lo .IDLO .note {
            top: -5px;
        }
    }
}


/* Fin Livre d'or */

/* Contact */
.form .col-xs-9 .s12 {
    width: 370px;
}


.form-group {
    margin-bottom: 10px;
}

label {
    margin-bottom: 3px;
}

.form-control {
    padding: 6px 12px;
}

textarea.form-control {
    height: 212px;
}

form .form-control:focus {
    border-width: 1px;
    border-style: solid;
    outline: 0;
}
.template_contact #bloc-fil-title{
    display: none !important;
}

/* Fin Contact */

/***** FIN CONTENU *****/

/********************************************/
/****************** FOOTER ******************/
/********************************************/

.footer_p {
    text-align: center;
    padding: 5px 15px;
    .mention a {
        text-decoration: none;
    }
}

/***** FIN FOOTER *****/

/*******************************************************************/
/****************** A GARDER POUR TOUS LES MODELS ******************/
/*******************************************************************/

/*Gérer les images dans les tableaux*/
.content_p {
    position: relative;
}

.content_p img {
    max-width: 100% !important;
    height: auto !important;
    display: inline-block;
}

.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
    max-width: none !important;
}

table {
    position: relative;
    table-layout: fixed;
}

.footer_p.footer_p .links_p td img, .footer_p.footer_p .links_p img {
    width: auto !important;
    max-width: 100% !important;
}

.content_p table {
    max-width: 100% !important;
}
.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title{
    h2, .h2, h1, .h1{
        display:none;
    }
}

/***** IE *****/
/* IE 10 */

/* IE 8 */

/* Template Panier */
body.cmonsite-panier-2 .template-panier.template-panier-2{
    height:49px;
    border:none !important;
    #panier #header-panier{
        padding: 15px 10px;
        box-sizing: border-box;
        display: block;
        height:auto;
        .count-live{
            top:auto;
            margin-top: 9px;
            margin-left: 0;
        }
    }
}
body.cmonsite-panier-3 .template-panier.template-panier-3{
    border:none !important;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/***************************************************/

@media #{$large-and-down} {
    .wrapper, nav#nav-principal {
        width: $medium-screen - 20;
        margin: 0 auto;
    }
    .wrapper img {
        height: auto;
    }
    .produits-accueil {
        max-width: 100%;
    }
    #headercontent {
        width: 100%;
    }
    .bx-wrapper .bx-controls-direction a {
        top: 45%;
    }
    .bx-wrapper .bx-prev {
        left: 10px;
    }
    .bx-wrapper .bx-next {
        right: 10px;
    }
    .col-md-10 {
        padding-left: 15px;
        padding-right: 5px;
    }
    .produits-accueil .produits .desc {
        padding: 42px 10px 20px;
    }
    .produits-accueil .produits .mask-btn {
        bottom: initial;
        top: 83%;
    }
    .produits-accueil .produits .mask-btn a.button {
        padding: 2px 15px;
    }
}

@media #{$medium-and-down} {

    .wrapper, nav#nav-principal {
        width: $small-screen - 20;
        margin: 0 auto;
    }
    p {
        word-wrap: break-word;
    }
    #headercontent img {
        max-width: 100%;
        height: auto;
    }
    .wrapper h1, .wrapper .h1 {
        font-size: 1.8rem;
    }
    .wrapper h2, .wrapper .h2 {
        font-size: 1.6rem;
        padding: 0 55px;
        margin-bottom: 10px;
    }
    .wrapper h3, .wrapper .h3 {
        font-size: 1.4rem;
    }
    .wrapper h4, .wrapper .h4 {
        font-size: 1.3rem;
    }
    .wrapper h5, .wrapper .h5 {
        font-size: 1.2rem;
    }
    .wrapper h6, .wrapper .h6 {
        font-size: 1rem;
    }
    .produits-accueil .produits .desc {
        display: none;
    }
    .produits-accueil .produits .mask-btn {
        top: 45%;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: 1rem;
    }
    .sidebar.content_p {
        width: 20.666667%;
    }
    .col-md-10 {
        width: 79.333333%;
    }
    .form-group textarea {
        width: 100% !important;
    }
    .message_lo .sous-titre {
        font-size: 1rem;
        padding: 2px 110px 2px 20px;
        margin-bottom: 8px;
    }
    .slide-text {
        top: 60%;
    }
    .search-bar .search-bar-icon {
        width: 7%;
    }
    .list-produits {
        transform: initial;
    }
    .sidebar.content_p {
        display: none;
    }
    .col-md-10 {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .search-bar .search-bar-icon {
        width: 10%;
    }
    .info-photo p {
        display: none;
    }

    .wrapper #fiche-produit .container-comment > div {
        padding: 20px 0 !important;
    }
    #fiche-produit .form-group {
        padding: 0 7px !important;

        select {
            width: 100% !important;
        }
    }
    #fiche-produit .form-control {
        padding: 6px 5% !important;
    }
    .wrapper #fiche-produit .top_fiche .addbasket {
        width: 100%;
        margin: 15px 0;
    }
    #resume .button, #information .button {
        padding: 5px 8px;
    }
    .template_livreor_2 .list-messages .message_lo .contenuMsgLO .note{
        left:10px;
        right:auto;
    }
}

@media #{$medium-and-up} {
    .menu_burger {
        display: none;
    }
}

@media #{$small-and-down} {
    /* Tableaux responsive */
    @import '../../design/helper/responsive/mobile/mobile';
    body .template-panier-2 #header-panier{
        top:-49px;
    }
    body .template-panier-2 #paniercontent{
        top:1px;
    }
    #headercontent{
        height:auto !important;
    }
    .slogan{
        left:0 !important;
        top:0 !important;
        right:auto !important;
        bottom:auto !important;
        text-align:center !important;
        font-size:18px !important;
        width:100% !important;
        position:static !important;
        padding-bottom:15px !important;
        float:none !important;
        height: auto !important;
    }
    .logo{
        padding-bottom: 15px;
        position: static !important;
        display:block !important;
        margin:0 auto 15px !important;
        padding-top:15px;
        height:auto !important;
        text-align:center;
        width: 100% !important;
        float:none !important;
        img{
            max-height: 70px !important;
            width: auto !important;
            max-width:100% !important;
            height:auto !important;
            position:static !important;

        }
    }
    .wrapper-content {
        width: 95%;
        margin: 0 auto;
    }
    .wrapper, nav#nav-principal {
        width: 100%;
        margin: 0 auto;
    }
    #block-flottant-search {
        display: none;
    }

    nav#nav-principal ul li a {
        padding: 6px 10px;
        width: 80%;
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    nav#nav-principal > ul > li.actif > a::before, nav#nav-principal > ul > li.actif > a::after {
        content: none;
    }

    /* Nav mobile */
    header #nav-principal {
        text-align: left;
        border: 1px solid #ccc;
        margin-bottom: 10px;
        padding: 0;
        position: relative;
    }
    .menu {
        position: relative;
        z-index: 1050;
        min-height: initial;
    }
    .menu_burger {
        cursor: pointer;
        background-color: #fff;
        display: block;
        padding: 16px 15px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        font-size: 1.1em;
        text-align: center;
    }
    .menu_burger::before {
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    .menu #nav-principal > ul {
        display: none;
        z-index: 1500;
    }
    .menu #nav-principal.nav_ouverte > ul {
        display: block;
        position: absolute;
        width: 100%;
    }
    .menu #nav-principal > ul > li {
        float: none;
        margin-right: 0;
        display: block;
    }
    .menu nav#nav-principal li {
        margin-bottom: 0;
        border-bottom: 1px solid #ccc;
    }
    .hasDropdown {
        position: relative;
    }
    .dropDown {
        position: absolute;
        right: 2%;
        top: 4px;
        font-weight: bold;
        display: block;
        height: 20px;
        width: 7%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
    }
    .menu #nav-principal li ul {
        display: block;
    }
    nav#nav-principal > ul > li > ul {
        overflow: visible;
        position: absolute !important;
        width: 100%;
        > li {
            display: block;
            width: 100%;
            position: relative;
        }
    }
    nav#nav-principal > ul > li > ul {
        &, ul{
            position:relative !important;
            height:auto;
        }
    }
    nav#nav-principal ul li ul ul{
        width:100%;
        height:auto;
        position:relative;
        overflow:visible;
        left:0;
    }
    aside.sidebar{
        width:100%;
    }
    .menu #nav-principal .slide-toggle {
        position: relative;
        overflow: hidden;
    }
    .menu #nav-principal .slide-toggle, .menu #nav-principal .slide-toggle ul, .menu #nav-principal .slide-toggle li {
        width: 100% !important;
    }
    .menu #nav-principal .slide-toggle li {
        padding-left: 10px;
    }
    /* Fin nav mobile*/
    #wrapper {
        padding: 5px;
        overflow: hidden;
    }
    .wrapper {
        padding: 10px;
    }
    .wrapper h1, .wrapper .h1 {
        font-size: 1.6rem;
    }
    .wrapper h2, .wrapper .h2 {
        font-size: 1.4rem;
        padding: 0 45px;
    }
    .wrapper h3, .wrapper .h3 {
        font-size: 1.2rem;
    }
    .wrapper h4, .wrapper .h4 {
        font-size: 1.1rem;
    }
    .wrapper h5, .wrapper .h5 {
        font-size: 1rem;
    }
    .wrapper h6, .wrapper .h6 {
        font-size: .9rem;
    }
    .wrapper h1 span, .wrapper h2 span, .wrapper h3 span, .wrapper h4 span, .wrapper h5 span, .wrapper h6 span,
    .wrapper .h1 span, .wrapper .h2 span, .wrapper .h3 span, .wrapper .h4 span, .wrapper .h5 span, .wrapper .h6 span {
        font-size: inherit !important;
        font-weight: inherit !important;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: .9rem;
    }
    .produits .nomprod, .produits .desc, .produits .prix, p.attr {
        padding: 0 5px;
    }
    .produits .nomprod {
        font-size: .9rem;
        padding-top: 14px;
    }
    .button, button {
        font-size: .9rem;
        padding: 5px 7px;
    }
    .search-bar .search-bar-icon {
        width: 14%;
    }
    .message_lo .sous-titre {
        font-size: 1rem;
        padding: 2px 110px 2px 15px;
        margin-bottom: 8px;
    }
    .zoomPup, .zoomWindow {
        display: none !important;
    }
    .zoomPad img {
        opacity: 1 !important;
    }
    .wrapper #fiche-produit .wrap-images {
        width: 100%;
    }
    .wrapper .zoomPad {
        cursor: default;
    }
    .wrapper #fiche-produit .wrap-description {
        width: 100%;
        float: none;
        padding-left: 10px;
    }
    .wrapper #fiche-produit #captcha div:last-child {
        width: 40%;
    }
    .wrapper #fiche-produit #captcha {
        width: 100%;
    }
    .wrapper #fiche-produit #captcha div:first-child {
        width: 45%;
    }
    aside#minisite-sidebar {
        display: none;
    }
    nav#nav-principal > ul > li:hover {
        animation: none;
    }

    body .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more{
        max-width: calc(100% - 90px);
        bottom:auto !important;
    }
    body .accueil_diaporama_template .slide .slide-text .content-slide-text > p{
        top:auto !important;
        bottom:10px !important;
    }
}

@media screen and (max-width: 360px) {

    .galerie {
        width: 100%;
        margin: 5px 0;
    }

    #headercontent {
        height: 95px;
    }
    body {
        font-size: 13px;
    }

    .wrapper h1, .wrapper h2, .wrapper h3, .wrapper h4, .wrapper h5, .wrapper h6,
    .wrapper .h1, .wrapper .h2, .wrapper .h3, .wrapper .h4, .wrapper .h5, .wrapper .h6 {
        line-height: 1.2;
    }
    .wrapper h1,
    .wrapper .h1 {
        font-size: 1.4rem;
    }
    .wrapper h2,
    .wrapper .h2 {
        font-size: 1.3rem;
        padding: 0 25px;
        margin-bottom: 10px;
    }
    .produit_etiquette {
        padding: 3px 10px;
        h3, .h3 {
            font-size: .8rem;
        }
    }
    .produits-accueil .info-prod {
        padding: 0 6px;
    }
    .wrapper #fiche-produit .remise-produit {
        width: 45px;
        height: 44px;
        padding: 14px 5px 5px;
        right: 8px;
    }
    .prix {
        font-size: 13px;
    }
    p.attr select {
        max-width: 100%;
    }
    .button, button {
        font-size: .8rem;
        padding: 5px 9px;
    }
    .col-md-10 {
        padding: 0;
    }
    .search-bar .search-bar-icon {
        width: 16%;
    }
    .message_lo .sous-titre {
        font-size: .9rem;
        padding: 2px 103px 2px 10px;
        margin-bottom: 8px;

    }
    .message_lo .note {
        right: 8px;
        height: 32px;
        padding: 9px 7px;
    }
    form .row .col {
        padding: 0;
        max-width: 100%;
    }
    form .content-tab .row .col {
        padding: 0 0.5rem;
    }
    #panier {
        width: 85%;
        right: 7.5%;
    }
}

@media screen and (max-width: 320px) {
    .logo {
        left: 10px;
    }
    .wrapper {
        width: 100%;
    }
    .produits-accueil .produits .addbasket {
        margin: 5px 0;
    }
}

.template_fiche_produit_2 #fiche-produit {
    .wrap-description h1,
    .wrap-description .h1{
        margin-bottom:20px;
    }
}
.template_fiche_produit{
    #fiche-produit{
        .bloc-quantite{
            justify-content: left;
            margin-bottom: 14px;
        }

        .ajouter-panier {
            &.block-addbasket{
                display: inline-block;
                width: auto;
            }
        }
    }
}

// Modifications pour le flux panier

/*#resume table thead td, #resume table thead th {
  background: #298dff;
  color: #fff;
}*/

/*#resume table tbody .fa-minus::before,
#resume table tbody .fa-plus::before, {
    background: #298dff;
}*/

/*#resume, #finish {
    .block-price .block-with-background .bloc-total {
        li.content-payment-global {
            background: #298dff;
            color: #fff;
        }
    }
}*/

/*#finish h5 {
    background: #298dff;
}*/

#wrapper .template_flux_panier_1.steps-panier {

    .side-tabs ul li {
        color: #298dff;

        &.step-done {
            background: #298dff;
            border-bottom: 2px solid #298dff;
        }

        &.active {
            color: #298dff;
            border-bottom: 2px solid #298dff;

            a, span {
                color: #298dff;
            }
        }
    }
}